body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.lang{
  position: fixed;
  top: 35px;
  right: 35px;
  width: 45px;
  height: 20px;
  border: 1px solid #BEB689;
  border-radius: 10%;
  color: #FFB601;
  text-align: center;
  font-size: 20px;
  line-height: 19px;
}
.on{
  font-size: 15px;
  position: relative;
  top: -3px;
}
.notOn{
  font-size: 10px;
  position: relative;
  bottom: -1px;
  color: #bebebe;
}

/* -----------------公共样式区----------------- */
/* 按钮样式 */
.CommonButtonStyle{
  font-family: "FZZhongDengXian-Z07S"!important;
  background-color: #13161b!important;
  color: #FFB601!important;
  border:1px solid #BEB689!important;
}
.iconBtn{
  padding: 4px!important;
}
.textInfo{
  font-family: "FZZhongDengXian-Z07S"!important;
  font-size: 14px!important;
  color: rgba(255, 255, 255, 0.7)!important;
}
.textWallet{
  float: left!important;
  font-family: "FZZhongDengXian-Z07S"!important;
  color: #FFF!important;
}
.textImport{
  float: left!important;
  font-family: "FZZhongDengXian-Z07S"!important;
  color: #FFB601!important;
  text-decoration:none!important;
}
.inputLabel{
  font-size: 1.2rem!important;
  font-family: "FZZhongDengXian-Z07S"!important;
  color: #FFF!important;
}
.formHelperText{
  font-family: "FZZhongDengXian-Z07S"!important;
  font-size: 0.5rem!important;
}
.MuiInputBase-root{
  font-family: "FZZhongDengXian-Z07S"!important;
}

.pageFoot{
  height: 60px;
}
.CopyButtonStyle{
  height: 56px!important;
  border-radius: 4px!important;
  font-family: "FZZhongDengXian-Z07S"!important;
  background-color: #13161b!important;
  color: #FFB601!important;
  border:1px solid #BEB689!important;
}

@media only screen and (min-width: 1000px) {
  .modalWidth{
    width: 43%;
  }
  .modalWidthTwo{
    width: 43%;
  }
  .modelHeight{
    height: 60%!important;
  }
}
@media only screen and (min-width: 430px) and (max-width: 1000px) {
  .modalWidth{
    width: 48%;
  }
  .modalWidthTwo{
    width: 48%;
  }
  .modelHeight{
    height: 60%!important;
  }
}
@media only screen and (max-width: 430px) {
  .modalWidth{
    width: 95%;
  }
  .modalWidthTwo{
    width: 100%;
  }
  .modelHeight{
    height: 100%!important;
  }
}
.codeWidth{
  width: 100%;
  overflow-x: auto;
}
.lineFeed{
  white-space:normal; 
  word-break:break-all;
}
@media only screen and (max-width: 350px) {
  .butWidth{
    width: 120px;
  }
}
@media only screen and (min-width: 350px) {
  .butWidth{
    width: 139px;
  }
}