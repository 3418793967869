.footNavigationStyle{
    width: 100%;
    position: fixed;
    bottom: 0;
    max-width: 1100px;
    text-align: center;
    margin:0 auto; 
    left: 50%;
    transform: translateX(-50%);
}

.am-tab-bar-bar{
    border-top:0px;
}

/* footNavigationOld样式 */
.MuiBottomNavigation-root{
    background-color: #212733!important;
}
.MuiBottomNavigationAction-root.Mui-selected{
    color: #FFB601!important;
}