
.detailsBackgrad{
    background-color: #212733!important;
    width: 100%;
}

.leftSide{
    width: 25%;
    height: 100%;
    float: left;
}
.rightSide{
    width: 75%;
    height: 100%;
    float: right;
}

.detailsTitleMagin{
    margin-top: 20px;
    width: 100%;
    height:80px;
}

.detailsUsdt{
    width: 40px;
    height: 40px;
    display: flex;
    overflow: hidden;
    position: relative;
    font-size: 1.25rem;
    align-items: center;
    flex-shrink: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    margin-left: 25px;
    margin-top: 15px;
}

.detailsBinance{
    height: 20px;
    line-height: 20px;
    float: left;
    font-size: 12pt;
    margin-left: 45px;
}
.detailsAddress{
    width: 100%;
    height: 20px;
    text-align: left;
    overflow-x: auto;
    margin-top: 5px;
}
.detailsRemarks{
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 10pt;
}
.detailsTime{
    width: 100%;
    height: 10px;
    text-align: right;
    font-size: 10px;
    text-align: left;

}
.detailsAmount{
    width: 100%;
    height: 10px;
    font-size: 13px;
    text-align: left;
}

.detailslistLeft{
    padding-left: 15px;
    font-size: 13px;
}
.detailslistRight{
    text-align: right;
    padding-right: 15px;
}

 .detailslist{
    padding-top: 5px;
    padding-left: 15px;
    height: 25px;
    font-size: 13px;
    background-color: #121212;
}
.detailslistRightHead{
    text-align: right;
    padding-right: 20px;
    font-size: 13px;
}

.detailslistHeader{
    height: 34px;
    background-color: #141821;
    color: #73767d;
    line-height: 34px;
    font-size: 13px;
}
.margintop{
    margin-top: 5px;
}
.detailsBackgradnew{
    width: 100%;
    height: 5px;
}
.remarks{
    font-size: 13px;
    background-color: #121212;
    padding: 0px 15px 4px;
}